$ligthGreen: #38EF7D;
$ligthGreen2: #51e180;
$darkGreen: #208948;
$white: white;
$gray: #212529;
$black: #000;
$black2: #2b2b2b;
$menu: #ffffff59;
$greenTransparent: #38ef7da5;
$red: #e94b50;
$greenTransparent2: #38ef7d0d;
$border: #ffffff19;
$green: #1eb588;
$gray2: #313131;
$gray3: #ffffff0d;
$gray4: #707070;

@font-face {
  font-family: 'exo';
  src: url('./assets/fonts/exo/exo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.marvin-regular {
  font-family: marvin, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.marvin-round-regular {
  font-family: marvin-round, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.marvin-shadow-regular {
  font-family: marvin-shadow, sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "exo", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $menu;
  background: $black;
}

a:hover {
  background: -webkit-linear-gradient($ligthGreen, $darkGreen);
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}

img {
  max-width: 100%;
}

p {
  color: $menu;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.5);
  flex-direction: column;

  .loading-text p {
    color: $white;
    font-size: 20px;
    text-align: center;
  }
}

.alert-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(ellipse at center, #00000000, #00000078),
    radial-gradient(ellipse at bottom, #00000000, #000000);
  padding: 15px;

  .close {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  .toast-container {
    z-index: 2;
    border-radius: 10px;
    background-image: linear-gradient(180deg, #000000ad, black),
      url(../public/assets/img/baby.jpg);
    width: 300px;
    height: 300px;
    max-width: 100%;
    box-shadow: 0px 2px 17px $ligthGreen2;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
  }

  .toast-icon {
    font-size: 40px;
    color: $ligthGreen2;
    font-family: "sf-semibold";
  }

  .toast-icon.toast-error {
    color: $red;
  }

  .toast-content {
    margin-bottom: 20px;

    p {
      font-size: 16px;
    }

    font-size: 16px;
    margin-top: 20px;
  }

  a {
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none !important;
  }
}

#header {
  height: 65px;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 99;
  transition: 0.2s ease-in-out;
  backdrop-filter: blur(20px);
}

.header-stiky {
  background: black;
}

.navbar-brand-sticky {
  width: 176px;
}

.navbar-toggler {
  .fa {
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.nav-link {
  font-family: "exo";
  color: $menu !important;
  font-size: 16px;

  &:before {
    content: "";
    width: 0%;
    height: 3px;
    transition: 0.2s ease-in;
    background: linear-gradient(180deg, $ligthGreen, $darkGreen);
    position: absolute;
    top: 0;
    border-radius: 200px;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, 0);
  }
}

.nav-link.active {
  color: $white !important;

  &:before {
    width: 60%;
    transition: 0.2s ease-in;
  }
}

.nav-item {
  margin-right: 40px;
}

.qorx-progress {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
  background-color: rgba(56, 239, 125, 0.5);
  border: 2px solid rgba(56, 239, 125, 1);
  border-radius: 10px;
  color: #fff;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  font-family: "sf-semibold";

  &:hover {
    color: $white !important;
    -webkit-text-fill-color: inherit;
  }
}

.qorb-badge {
  background-color: rgba(245, 30, 41, 0.5);
  border: 2px solid rgb(228, 84, 84);
}

.qorb-staking {
  background-color: rgba(45, 15, 179, 0.5);
  border: 2px solid rgb(47, 158, 231);
}

.greenBtn,
.greenBtn span {
  background: linear-gradient($ligthGreen, $darkGreen) !important;
  color: $white !important;
  border-radius: 8px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "exo";
  font-weight: 400;
  line-height: 23px;
  padding-bottom: 5px;

  &:hover {
    color: $black !important;
    -webkit-text-fill-color: inherit;
  }
}

.greenBtn1,
.greenBtn span {
  background: linear-gradient(180deg, $ligthGreen, $darkGreen) !important;
  color: $black !important;
  border-radius: 8px;
  height: 40px;
  display: inline-flex;
  width: 120px;
  align-items: center;
  justify-content: center;
  margin: 10px;
  text-decoration: none;
  font-family: "sf-semibold";

  &:hover {
    color: $white !important;
    -webkit-text-fill-color: inherit;
  }
}

button.greenBtn {
  border: 0;
}

.button-top-container {
  display: flex;
  position: relative;
}

.text-align-center {
  text-align: center;
}

.cut-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

button.btnTransparent,
.btnTransparent,
.btnTransparent span {
  background: -webkit-linear-gradient($ligthGreen, $darkGreen);
  -webkit-background-clip: text;
  color: $green;
  // -webkit-text-fill-color: transparent;
  height: 40px;
  display: inline-flex;
  width: 90px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "exo";
  border-radius: 8px;
  border: 0;
  position: relative;  
  padding-bottom: 5px;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(180deg, $ligthGreen, $darkGreen);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.banner-header {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .bg {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    /*filter: blur(8px);*/
  }

  #bg-video {
    position: absolute;
    right: 0;
    top: -100px;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: 0.8;
  }

  .content-bg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }

  .banner-title {
    font-size: 72px;
    font-family: "exo", sans-serif;
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }

  .extra-text {
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;    
  }

  .banner-subtitle {
    color: $menu;
    font-size: 20px;
    font-family: "exo", sans-serif;
    text-align: center;
    margin-top: 20px;
  }

  .button-container {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 40px;
  }

  .btnTransparent {
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    color: #32b964;
    // -webkit-text-fill-color: transparent;
    height: 40px;
    display: inline-flex;
    width: 120px;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: "helvetica";
    font-weight: 400;
    line-height: 23px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px $ligthGreen2;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      padding: 1px;
      background: linear-gradient(180deg, $ligthGreen, $darkGreen);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: destination-out;
      mask-composite: exclude;
    }
  }

  .lottie {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }
}

.space {
  margin: 0 10px;
}

.green-title {
  font-size: 52px;
  background: -webkit-linear-gradient($ligthGreen, $darkGreen);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  text-shadow: 0px 0px 3px $greenTransparent;
  margin: 40px 0px 20px 0px;
}

.green-title-small {
  font-size: 42px;
  background: -webkit-linear-gradient($ligthGreen, $darkGreen);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  text-shadow: 0px 0px 3px $greenTransparent;
  margin-bottom: 20px;
}

.darkbg {
  background: $black;
}

.gradientBg {
  position: relative;

  &::after {
    background: transparent linear-gradient(0deg,
        #38ef7dbf 0%,
        #11998ebe 16%,
        $black 32%,
        #38ef7dbf 53%,
        $darkGreen 71%,
        $black 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 1;
    opacity: 0.2;
  }
}

.darkgreen-bg {
  background-image: linear-gradient(#03040794, #030407),
    url(../public/assets/img/filter-bg.png);
  padding-top: 40px;
}

.section {
  padding: 40px 0;
  position: relative;
  z-index: 2;
}

.thumbnail {
  max-width: 100%;
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 20px;

  .overlay {
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    opacity: 0.8;
  }
}

.main-image {
  max-width: 100%;
  // width: 700px;
  height: 0;
  position: relative;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
  transition: visibility 0s, opacity 0.5s linear;
  opacity: 0;
  visibility: hidden;

  video {
    width: 100%;
  }

  &:before {
    background: radial-gradient(ellipse at center, #00000000, #00000078),
      radial-gradient(ellipse at bottom, #00000000, #000000);
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
  }
}

.main-image.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
}

.arena-sect {
  background: linear-gradient(rgba(0, 0, 0, 0.74), rgba(0, 0, 0, 1)),
    url(https://digital.qorbiworld.com/arena2.jpg);
  background-size: cover;
  background-position: left center;

  .bg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 70px;

    .bg {
      width: 280px;
      height: 200px;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
    }
  }
}

.baby-video {
  position: relative;

  video {
    width: 100%;
  }

  &:before {
    content: "";
    background: radial-gradient(ellipse at center, #00000029, #00000000),
      radial-gradient(ellipse at bottom, #00000000, #000000f2);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &:after {
    filter: blur(4px);
    content: "";
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    background: linear-gradient(180deg, #000000ad, black);
  }
}

.weapon-sect {
  background-image: url(https://digital.qorbiworld.com/bigblur.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  .bg-gun {
    height: 300px;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;
    background-image: url(../public/assets/img/Weapons_BG.jpg);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      width: 170px;
      max-width: 100%;
      transform: scale(1);
      transition: 0.3s ease;
    }

    &:hover img {
      transform: scale(1.5);
    }
  }
}

.footer {
  padding: 40px 0 0;

  .logo-footer {
    width: 100px;
    max-width: 100%;
  }

  .top-section {
    align-items: center;
    margin-bottom: 5px;
  }

  .navbar-footer {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .active:before {
      display: none;
    }
  }

  .social-container {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding: 0 10px;

      img {
        width: 40px;
      }
    }
  }

  .bottom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 0.5px solid #21252940;
    padding-top: 20px;
    padding-bottom: 5px;

    ul {
      list-style: none;
      display: flex;

      li {
        padding-right: 5px;
      }

      a {
        color: $menu;
        text-decoration: none;
      }
    }
  }
}

.payment {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;

  li {
    padding-right: 5px;
  }

  img {
    width: 50px;
  }
}

.baby-video {
  margin-top: 40px;

  video {
    width: 100%;
  }
}

.checkbox-container {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .active {
    color: $white;
    font-family: "sf-medium";
  }

  .checkbox {
    border: 0.5px solid $border;
    color: $white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 4px;
  }

  .checkbox.active {
    background: linear-gradient(180deg, $ligthGreen, $darkGreen);
    border-radius: 4px;
    border: 0;
  }
}

.sold-out-text {
  position: relative;
  top: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  color: red;
  font-size: 28px;
  font-weight: bold;
  text-shadow: 2px 2px 0 white;
}

.nft-card {
  cursor: pointer;
  margin-bottom: 40px;

  .nft-bg {
    height: 350px;
    width: 100%;
    border-radius: 25px;
    background-size: cover;
    background-position: center;
    position: relative;

    .tag {
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, $ligthGreen, $darkGreen);
      font-size: 12px;
      color: white;
      padding: 5px 10px;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 10px;
    }

    h4 {
      color: #2fdb81;
      text-align: center;
    }
  }

  .name-container {
    display: flex;
    align-items: center;
    margin-top: 20px;

    img {
      width: 20px;
      margin-right: 20px;
    }

    h4 {
      margin: 0;
      background: -webkit-linear-gradient($ligthGreen, $darkGreen);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 15px;
    }
  }

  .bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;

    .bid {
      font-size: 12px;
      font-family: "sf-medium";
    }

    .price {
      color: $white;
      margin-right: 5px;
      font-family: "sf-medium";
    }

    .sol {
      background: -webkit-linear-gradient($ligthGreen, $darkGreen);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-family: "sf-semibold";
    }
  }
}

.load-more {
  font-size: 16px;
  background: -webkit-linear-gradient($ligthGreen, $darkGreen);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "sf-semibold";
  display: block;
  text-align: center;
  cursor: pointer;
}

.single-baby {
  .main-image-container {
    img {
      border-radius: 20px;
    }
  }

  .thumbnail {
    border-radius: 10px;
    width: 100%;
    height: 100px;
  }

  .baby-name {
    color: $white;
    font-family: "sf-black";
    word-break: break-word;
  }

  .baby-price {
    border: 1px solid $ligthGreen2;
    display: inline-block;
    padding: 2px 10px;
    color: $ligthGreen2;
    border-radius: 7px;
    font-family: "sf-medium";
    font-size: 16px;
  }

  .tab-header {
    border: 1px solid $greenTransparent2;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-top: 20px;
  }

  .tab {
    color: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .tab.active {
    background: #ffffff0d;
    color: $white;
  }

  .attribute-title {
    color: $white;
    font-size: 14px;
    margin-top: 20px;
    font-family: "sf-semibold";
  }

  .attributes-container {
    .col-md-6 {
      margin-bottom: 20px;
    }

    .content {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 10px;
      height: 100%;

      span {
        color: $white;
        font-family: "sf-medium";
      }
    }
  }

  .greenBtn {
    width: 100%;
    margin-top: 40px;
  }

  #baby-video {
    width: 100%;
    border-radius: 10px;
  }

  .video-image {
    position: relative;

    .overflow {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      background: -webkit-linear-gradient($ligthGreen, $darkGreen);
      border-radius: 50%;
      color: $white;
      text-align: center;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }
}

.steps {
  list-style: none;
  counter-reset: my-counter;
  padding-left: 0;

  li:nth-child(odd) {
    padding-left: 60px;
    margin-bottom: 20px;

    &:before {
      content: counter(my-counter);
      color: $black;
      left: 0;
      font-family: "sf-black";
      font-size: 40px;
      width: 50px;
      height: 50px;
      background: -webkit-linear-gradient($ligthGreen, $darkGreen);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .green-title-small {
      text-align: left;
    }
  }

  li:last-child {
    border-bottom: 0;
  }

  li {
    counter-increment: my-counter;
    position: relative;
    padding-right: 60px;
    margin-bottom: 20px;
    border-bottom: 0.5px solid $gray;
    padding-bottom: 10px;

    &:before {
      content: counter(my-counter);
      color: $black;
      right: 0;
      font-family: "sf-black";
      font-size: 40px;
      width: 50px;
      height: 50px;
      background: -webkit-linear-gradient($ligthGreen, $darkGreen);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }

    .green-title-small {
      text-align: right;
    }
  }

  .content {
    font-size: 18px;

    a {
      background: -webkit-linear-gradient($ligthGreen, $darkGreen);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.wallet-options {
  .btnOpt {
    width: 100%;
    margin-bottom: 20px;

    img {
      width: 30px;
      margin-right: 20px;
    }
  }
}

.visible {
  opacity: 1;
  height: inherit;
}

.invisible {
  opacity: 0 !important;
  height: 0 !important;
}

@media (max-width: 998px) {

  #bg-video,
  #bg-video-baby {
    display: none;
  }

  .thumbnail-container {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .thumbnail {
      width: 48%;
      height: 200px;
    }
  }

  .thumbnail-cont {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;

    img {
      width: 48% !important;
      height: 200px !important;
    }

    .video-image {
      width: 48%;

      img {
        width: 100% !important;
      }
    }
  }

  .baby-video {
    .pict {
      background-image: url(../public/assets/img/arenascreenshot.jpg);
      background-size: cover;
      background-position: top center;
      width: 100%;
      height: 100%;
      margin-top: 20px;
      min-height: 300px;
    }

    &:before,
    &:after {
      opacity: 0;
    }
  }

  .media-container {
    flex-direction: column-reverse;

    .thumbnail-container {
      .video-image {
        img {
          width: 100%;
        }
      }
    }
  }

  .footer {
    overflow: hidden;
  }

  .navbar {
    background: $black;
  }

  .nav-link.active:before {
    display: none;
  }

  .steps {
    li {
      padding-left: 60px;
      padding-right: 0px;

      &:before {
        left: 0;
        right: inherit;
      }

      .green-title-small {
        text-align: left;
      }

      .content img {
        margin-top: 20px;
      }
    }

    li:nth-child(even) {
      .row {
        flex-direction: column-reverse !important;
      }
    }
  }
}

@media (min-width: 769px) {
  .main-image {
    background: transparent !important;
  }

  .team {
    .nft-bg {
      height: 200px !important;
    }
  }
}

@media (min-width: 999px) {
  .more-games {
    p {
      font-size: 18px;
      margin: 0px 200px;
    }
  }
}

@media (max-width: 998px) {
  .banner-header .content-bg {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .top-section {
    .col-12 {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  #header {
    .close {
      // display: none !important;
      transition: .2s ease-out opacity;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }

    .open {
      // display: block !important;
      transition: .2s ease-out opacity;
      height: 100%;
      opacity: 1;
    }
  }

  .bottom-footer {
    display: block !important;
    text-align: center;

    ul {
      display: block !important;
    }
  }

  .frame {
    margin-top: 40px;
  }

  .main-image {
    background-size: cover;
    background-position: center;

    video {
      display: none;
    }

    .cont {
      padding: 0 !important;
      font-size: 20px !important;
    }
  }

  .main-image.active {
    height: 300px;
    margin-bottom: 10px;
  }

  .thumbnail-container .title {
    font-size: 18px !important;
  }

  .signup-bg,
  .signin-bg {
    .content {
      padding: 0 20px !important;
      display: block !important;

      .box {
        margin: 20px auto 0;
      }

      .logo {
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  .roadmap .hide-mobile {
    display: none;
  }

  .roadmap .show-mobile {
    display: block !important;
  }
}

@media (max-width: 600px) {
  .banner-header .banner-title {
    font-size: 52px;
  }

  .banner-header .banner-subtitle {
    font-size: 18px;
  }

  .thumbnail-container .thumbnail {
    height: 120px;
  }

  .arena-sect .bg-container {
    display: block;

    .space {
      display: none;
    }

    .bg {
      margin: 0 auto 10px;
    }
  }
}

.pagination {
  justify-content: space-between;

  .page-link {
    background: transparent;
    border-color: $ligthGreen;
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
}

.codeinput {
  background: transparent;
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  height: 60px;
  padding: 20px;
  color: $ligthGreen2;
  font-size: 18px;
  border: 0.5px solid $border;

  &::placeholder {
    color: $menu;
  }
}

.btn-submit-cont {
  max-width: 300px;
  margin: 20px auto 0;
  position: relative;
}

.btn-submit {
  transform: translate(0px, -50%);
  color: $black;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(180deg, $ligthGreen, $darkGreen) !important;
  cursor: pointer;
}

.amount-container {
  margin-top: 20px;

  input {
    background: transparent;
    border: 0;
    text-align: center;
    font-size: 20px;
    color: $white;
  }

  i {
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
  }
}

.thumbnail-container {
  .title {
    background: -webkit-linear-gradient(#38ef7d, #11998e);
    -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    color: $green;
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-family: "sf-bold";
    top: 0;
  }
}

.main-image {
  .cont {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    background: linear-gradient(179deg, #000, transparent);
    font-family: "sf-semibold";
    height: 100%;
    color: #1eb588;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 25%;
    font-size: 30px;
  }
}

.button-affiliate {
  width: auto !important;
  padding: 20px;
}

@media (max-width: 998px) {
  .main-image .cont {
    padding: 0 20%;
  }

  .meet-baby .row {
    flex-direction: column-reverse;

    video {
      margin-top: 20px;
    }
  }
}

.meet-baby .container {
  border-bottom: 0.5px solid #212529;
  padding-bottom: 20px;
}

.team {
  .nft-bg {
    width: 65%;
    margin: 0 auto;
  }

  .name-container {
    justify-content: center;
    display: block;
    text-align: center;

    a {
      text-decoration: none;
    }

    h4 {
      font-size: 18px;
    }

    i {
      color: $green;
      margin-left: 5px;
    }
  }

  .position {
    color: $white;
    display: block;
    text-shadow: 1px 1px 1px $ligthGreen2;
    margin-bottom: 40px;
  }
}

.signin-bg {
  background-image: url("./assets/img/newLoginBG.jpg") !important;

  .forgot-password {
    display: flex;
    justify-content: space-between;
    color: $white;
    font-size: 12px;

    a {
      color: $white !important;
    }
  }
}

.signup-bg,
.signin-bg {
  background-image: url("./assets/img/bg-download.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  overflow-x: hidden;
  padding-top: 60px;

  .box {
    background: $black2;
    padding: 20px;
    border-radius: 26px;
    width: 500px;
    max-width: 100%;
  }

  .greenBtn {
    width: 100%;
    margin-top: 40px;
  }

  .content {
    display: flex;
    justify-content: flex-end;
    padding: 30px 40px;
    z-index: 2;
    position: relative;

    h2 {
      font-family: marvin, sans-serif;
      color: $white;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }

  $s1: "";
  $s2: "";
  $s3: "";

  @for $i from 1 through 400 {
    $s1: $s1 + random(1000) * 0.1vw + " " + random(1000) * 0.1vh + " " + 0 + " " + random(50) * -0.01rem + #fff;
    $s2: $s2 + random(1000) * 0.1vw + " " + random(1000) * 0.1vh + " " + 0 + " " + random(50) * -0.01rem + #fff;
    $s3: $s3 + random(1000) * 0.1vw + " " + random(1000) * 0.1vh + " " + 0 + " " + random(50) * -0.01rem + #fff;

    @if $i < 400 {
      $s1: $s1 + ",";
      $s2: $s2 + ",";
      $s3: $s3 + ",";
    }
  }

  .snow {
    border-radius: 50%;
    opacity: 0;
    position: absolute;
    top: 0;
    // animation-name: fall;
    // animation-timing-function: linear;
    // animation-iteration-count: infinite;
    animation: fall 0.5s infinite;
  }

  .layer1 {
    width: 0.2rem;
    height: 0.2rem;
    filter: blur(1.2px);
    box-shadow: #{$s1};
    animation-duration: 4s;
  }

  .layer1.a {
    animation-delay: -3s;
  }

  .layer2 {
    width: 0.1rem;
    height: 0.1rem;
    filter: blur(3px);
    box-shadow: #{$s2};
    animation-duration: 6s;
  }

  .layer2.a {
    animation-delay: -4s;
  }

  @keyframes fall {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
      // transform: translateY(200vh);
    }
  }
}

.field {
  background: $gray2;
  border: 1px solid $gray3;
  border-radius: 13px;
  position: relative;
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 15px;

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $gray4;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray4;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $gray4;
  }

  input {
    background: transparent;
    width: 100%;
    border: 0;
    outline: none;
    color: $white;
    padding: 0 20px;
  }

  span {
    position: absolute;
    right: 20px;
  }
}

.social-header {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;

  .line {
    background: $gray4;
    height: 1px;
    max-width: 100%;
    width: 100px;
    margin: 0 5px;
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    cursor: pointer;
    margin: 0 10px;
  }

  svg {
    width: 50px;
  }

  img {
    width: 50px;
  }
}

.download-game {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-section {
  .cont {
    text-align: center;
    padding: 0 0 20px 0;
    box-shadow: 1px 1px 18px 0px #38ef7d4f;
    position: relative;
    overflow: hidden;
    background: black;
  }

  .type {
    color: white;
    font-weight: 600;
    font-size: 24px;
    padding: 10px 0;
  }

  .price {
    font-size: 50px;
    font-weight: bold;
    color: $white;
    /*background: -webkit-linear-gradient(#38ef7d, #11998e);*/
    padding: 20px;
    margin-bottom: 20px;
  }

  .popular {
    position: absolute;
    /*background: -webkit-linear-gradient(#38ef7d, #11998e);*/
    width: 120px;
    right: -30px;
    transform: rotate(45deg);
    top: 20px;
    color: white;
  }

  .symbol {
    font-size: 14px;
  }

  .cents {
    font-size: 14px;
    line-height: 9px;
    display: inline-flex;
    flex-direction: column;
    text-align: left;

    b {
      font-size: 20px;
    }

    span {
      font-size: 10px;
    }
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 10px;
    }
  }
}

.roadmap {
  .show-mobile {
    display: none;

    ul {
      list-style-position: inside;
      padding-left: 0;
    }

    .number {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .road {
    width: 600px;
  }

  .right {
    position: absolute;
    right: 0;
    width: 50%;
    top: 250px;
  }

  .step h2 {
    font-size: 20px;
    color: $white;
  }

  .left {
    position: absolute;
    left: 0;
    width: 50%;
    top: 350px;

    img {
      transform: scale(-1);
    }

    ul {
      padding-right: 100px;
      padding-left: 0;
      list-style: none;

      li:after {
        content: "○";
        font-size: 7px;
        padding-left: 10px;
      }
    }
  }

  .step {
    margin-bottom: 40px;
  }

  .step-6 {
    padding-left: 80px;
  }

  .step-4 {
    padding-left: 140px;
  }

  .step-2 {
    padding-left: 50px;
  }

  .step-3 {
    padding-right: 140px;
  }

  .step-1 {
    padding-right: 100px;
  }

  .number {
    margin-right: 20px;
    margin-left: 20px;
    width: 40px;
    height: 40px;
    background: -webkit-linear-gradient($ligthGreen, $darkGreen);
    border-radius: 50%;
    color: $white;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  ul {
    list-style: circle;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 140px;
    font-size: 14px;
  }
}

.nft {
  video {
    width: 100%;
    border-radius: 20px;
  }

  .nft-bg {
    height: inherit !important;

    video {
      display: block !important;
    }
  }
}

.bg-circle {
  background: url(../public/assets/img/yucanna.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.upcoming {
  background: url(../public/assets/img/background-tocover.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

.roadmap1 {
  background: url(../public/assets/img/jagbackround.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.team {
  background: url(../public/assets/img/Rod_Yucana_Promo.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.logos-partners {
  img {
    max-height: 100px;
  }
}

.assets {
  .left-side {
    background-color: #1a1919;
    border-radius: 30px;
    padding: 20px;

    h2 {
      color: $white;
      font-size: 20px;
      font-family: "sf-medium";
    }

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .date {
        color: $ligthGreen;
        font-size: 12px;
      }

      .name {
        font-size: 12px;
        color: $white;
      }

      .price {
        border: 1px solid $ligthGreen;
        border-radius: 20px;
        color: $ligthGreen;
        font-size: 12px;
        padding: 2px 15px;
        font-family: "sf-medium";
      }
    }
  }

  .right-side {
    h2 {
      color: $white;
      font-size: 28px;
      font-family: "sf-bold";
    }

    .bg {
      background-image: url("./assets/img/signUp.jpg");
      background-size: cover;
      background-position: center;
      height: 300px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      position: relative;
      cursor: pointer;
    }

    .check {
      color: $ligthGreen;
      position: absolute;
      top: 10px;
      right: 10px;
      background: $black;
      padding: 5px 10px;
      border-radius: 8px;
    }

    .cont {
      padding: 10px 20px 20px;
      background-color: #1a1919;
      position: relative;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;

      h4 {
        color: $white;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }

    button {
      width: 100%;
      color: $white;
      height: 30px;
      font-family: "sf-regular";
      font-size: 14px;
    }

    .logo {
      position: absolute;
      right: 20px;
      top: -20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #292828;
      width: 50px;
      height: 50px;
      border-radius: 50%;

      img {
        width: 30px;
      }
    }
  }
}

.floating-btn {
  position: fixed !important;
  top: 90px;
  border: 0;
  right: 20px;
  height: 30px;
  z-index: 9;
  padding: 0 10px;
  background: #00c8ee;
  color: $white;
  border-radius: 8px !important;
}

.box-rounded {
  background: #1a1919;
  border-radius: 30px;
  padding: 35px;
  height: 100%;

  h2 {
    color: $white;
    font-size: 24px;
  }

  p {
    color: #a6a6a6;
    font-size: 16px;
    text-align: center;
  }

  ul {
    color: #a6a6a6;
    font-size: 16px;
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;
    }
  }

  .greenBtn {
    color: $white !important;
  }

  .d-flex {
    .greenBtn {
      color: $white !important;
      width: 60%;
    }

    .btnTransparent {
      width: 35%;
    }
  }
}

.txt-green {
  color: $ligthGreen;
}

.market {
  .nft-card {
    cursor: inherit;
  }

  .content {
    background-color: $black2;
    padding: 15px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    p {
      color: $gray4;
      font-size: 18px;
    }
  }

  .nft-bg {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }

  .bg-market {
    background: linear-gradient(102deg, $black, transparent),
      url(../public//assets/img/arenabg.jpg);
    background-size: cover;
    background-position: center;
    height: 400px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    h2 {
      color: $white;
      font-size: 30px;
      font-weight: bold;
    }

    h4 {
      font-size: 18px;
      color: $white;
      font-weight: 300;
    }
  }
}

.loadScreen {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.submenu {
  position: absolute;
  bottom: -70px;
  right: 0;
  background: black;
  padding: 5px 13px;
  border-radius: 5px;
  border: 1px solid #ffffff4f;
  z-index: 2;

  span {
    display: block;
    cursor: pointer;
  }
}

.overtouch {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.main-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.second-overlay {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(32, 255, 24, 0.5);
  padding: 20px;
}

.third-overlay {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(50, 50, 50, 0.8));
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  padding: 20px;
}

.black-overlay {
  background: black;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  padding: 50px;
  margin: 10px;
}

.buttonrng {
  min-width: 300px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #2ED881;
  background: linear-gradient(90deg, rgb(4, 110, 4) 0%, rgba(116, 206, 116, 1) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(116, 206, 116, .64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.buttonrng::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #00E68A;
  box-shadow: 0 0 60px rgba(0, 230, 138, .64);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.buttonrng:hover,
.buttonrng:focus {
  color: #313133;
  transform: translateY(-6px);
}

.buttonrng:hover::before,
.buttonrng:focus::before {
  opacity: 1;
}

.buttonrng::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00E68A;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.buttonrng:hover::after,
.buttonrng:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }

  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

/* SignIn.css */

.signin-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.signin-box {
  background-color: #2B2B2B;
  color: #FFFFFF;
  padding: 40px 40px;
  border-radius: 75px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 80%;
  max-width: 500px;
}

.signin-box h1 {
  color: #FFFFFF;
  font-size: 2em;
  margin-top: 0;
  font: normal normal normal 60px/60px Marvin;
  font-size: 45px
}

.welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white
}

.signin-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin-buttons button {
  background-color: #2ED881;
  color: #FFFFFF;
  border: none;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  width: max-content;
}

.signin-buttons button {
  width: calc(100% - 60px);
}

.register-form-group {
  margin-bottom: 20px;
}

.register-form-input[type="referral"],
.register-form-input[type="text"],
.register-form-input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  color: black;
}

.register-form-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #18be26;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.register-form-button:hover {
  background-color: #066913;
}

/* qor-collection button */
.glow-on-hover {
  width: 300px;
  height: 75px;
  border: none;
  outline: none;
  color: #32b964;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-family: "sf-semibold";
  font-size: 32px;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #0a746b, #11998e, #2CD482, #38ef7d, #00ff62, );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

/* hide-iframe */

@media (max-width: 1000px) {
  .hide-iframe {
    display: none;
  }
}

/* QorShop.css */
.qor-shop-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}

.qor-shop-left-container {
  width: 20%;
}

.qor-shop-right-container {
  width: 65%;
}

.qor-shop-qorb-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 60px;
}

.qor-shop-name-text {
  font: normal normal bold 40px/23px SF UI Display;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  margin-top: 10px;
}

.qor-shop-settings-text {
  font: medium SF Pro Display, sans-serif;
  font-size: 22px;
  color: white;
}

.qor-shop-dropdown-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.setting-container {
  background-color: #1A1919;
  border-radius: 40px;
  padding: 20px;
  width: fit-content;
  max-width: 250px;
}

.setting-label {
  font: medium 18px/1.5 SF UI Display, sans-serif;
  color: #A6A6A6;
}

.qor-shop-buttons {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

select,
input[type="text"] {
  background-color: #1A1919;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
}

.qor-shop-third-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 50px;
}

.qor-shop-third-container img {
  width: 20%;
  border-radius: 60px;
  height: auto;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .qor-shop-container {
    flex-direction: column;
  }

  .qor-shop-left-container {
    width: 50%;
  }

  .qor-shop-right-container {
    width: 100%;
  }

  .qor-shop-name-text,
  .qor-shop-settings-text {
    text-align: center;
  }

  .qor-shop-name-text {
    margin-top: 20px;
  }

  .qor-shop-buttons {
    justify-content: space-evenly;
  }

  .qor-shop-dropdown-container {
    justify-content: space-evenly;
  }

  .setting-container {
    max-width: 200px;
  }

  .qor-shop-third-container {
    margin-top: 10px;
  }
}

/* Elixir Logs */
.elixir-logs-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  margin-right: 10px;
}

.search-select {
  margin-right: 10px;
}

.search-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #45a049;
}

.logs-table {
  border-collapse: collapse;
  width: 80%;
  margin-bottom: 20px;
  background-color: #1a1a1a;
  color: white;
}

.logs-table th,
.logs-table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.logs-table th {
  background-color: #333;
}

.logs-table tr:nth-child(even) {
  background-color: #333;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #45a049;
}

// Misssions
.missions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.missions-content {
  background: linear-gradient(135deg, #46f19c, #1EB688);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 600px;
  margin: 0 20px;
  border: 2px solid #0a5c33;
}

.missions-content p {
  margin-bottom: 20px;
}

/* Bundles */
.bundle-container {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: white;
  background-color: #000;
}

.bundle-top-segment {
  display: flex;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.bundle-left-part {
  flex: 1;
  padding-right: 10px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.bundle-left-part h1,
.bundle-left-part p {
  color: white;
}

.bundle-left-part h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.bundle-left-part p {
  font-size: 1.2em;
}

.bundle-right-part {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.bundle-bordered-image {
  border: 5px solid transparent;
  padding: 10px;
  max-width: 70%;
  border-image: linear-gradient(to right, #ccc, #ddd) 1;
  border-image-slice: 1;
  border-radius: 10px;
}

.bundle-bottom-segment {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
}

.conditional-text {
  display: none;
}

@media (min-width: 800px) {
  .conditional-text {
    display: block;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 800px) {
  .bundle-top-segment {
    flex-direction: column;
  }

  .button-container {
    justify-content: center;
  }
}

.carousel-button-reset,
.carousel-button-burn {
  display: inline-block;
  margin: 10px 5px;
  background-color: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}

.carousel-button-burn {
  background-color: rgba(0, 128, 0, 0.7);
}

.bundle-static-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media (max-width: 1000px) {
  .hide-on-small-screen {
    display: none;
  }
}

.bundle-static-image-item {
  position: relative;
}

.bundle-static-image-item img {
  display: block;
  max-width: 100%;
  width: 17vw;
  height: auto;
}

@media (max-width: 800px) {
  .bundle-static-image-item img {
    width: 25vw;
  }
}

.bundle-static-image-item img.grey-filter {
  filter: grayscale(100%);
}

.bundle-image-number {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(255, 255, 255, 0.945);
  color: black;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.selected-nft {
  border: 5px solid green;
  border-radius: 20px;
}

.mix-skins-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

/* Bundle Home */
.bundle-promotion-container {
  background-color: #000;
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.bundle-promotion-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media (max-width: 1000px) {
  .bundle-promotion-content {
    flex-direction: column;
  }
}

.bundle-promotion-promo-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #5adb5e;
  font-weight: bold;
}

.bundle-promotion-promo-description {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: white;
  text-align: justify;
}

.bundle-promotion-carousel-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.bundle-promotion-carousel-slide {
  position: relative;
}

.bundle-promotion-carousel-slide img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.bundle-promotion-carousel-slide .bundle-promotion-legend {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.bundle-promotion-carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.5em;
  cursor: pointer;
  z-index: 1;
}

.bundle-promotion-carousel-button.prev {
  left: 10px;
}

.bundle-promotion-carousel-button.next {
  right: 10px;
}

.bundle-promotion-text {
  max-width: 600px;
}

.bundle-promotion-bundle-benefits {
  color: grey;
  margin-top: 30px;
}

.bundle-promotion-bundle-benefits h3 {
  color: green;
  font-size: 2em;
  margin-bottom: 10px;
}

.bundle-promotion-bundle-benefits ul {
  list-style-type: none;
  padding: 0;
}

.bundle-promotion-bundle-benefits li {
  font-size: 1.2em;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
}

// Beta Gamers
.beta-gamers-matches {
  padding: 20px;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
}

.beta-gamers-matches .section {
  padding: 20px;
}

.middle-container {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.qorbi-earnings {
  width: 45%;
  margin: 0;
}

.table-container {
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
}

.matches-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
}

.matches-table th,
.matches-table td {
  border: 1px solid #444;
  padding: 8px;
  text-align: center;
  color: #ddd;
}

.matches-table th {
  background-color: #555;
  color: #fff;
  position: sticky;
  top: 0;
}

.matches-table tr:nth-child(even) {
  background-color: #3a3a3a;
}

.matches-table tr:hover {
  background-color: #555;
}

.hot-news {
  padding: 20px;
  background-color: #1c1c1c;
  border-radius: 8px;
  color: #ddd;
}

.hot-news h2 {
  color: #0f0;
}

.hot-news p {
  color: rgb(255, 255, 255);
}

.second-middle-container {
  width: 45%;
}

@media (max-width: 1000px) {
  .middle-container {
    flex-direction: column;
  }

  .qorbi-earnings,
  .second-middle-container {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}

.button-container-tester {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-switch-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

// Trade Loaders
.trade-loader-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  text-align: center;
  color: white;
}

.trade-loader-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.button-container-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.button-group-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.button-group-home button {
  margin: 10px;
  min-width: 150px;
}

@media (max-width: 600px) {
  .button-group-home {
    flex-direction: column;
  }

  .button-group-home button {
    margin-bottom: 10px;
    margin-right: 0;
  }
}

//Profile Page

.loading-magnifying-glass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.profile-container {
  display: flex;
  padding: 75px 30px;
  border-radius: 15px;
  margin: 20px auto;
  color: white;
}

.profile-email {
  color: #888;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.profile-copy-icon {
  margin-left: 8px;
  color: #888;
  transition: color 0.3s;
}

.profile-copy-icon {
  color: #33E37F;
}

.profile-tournaments-table {
  width: 100%;
  border-collapse: collapse;
}

.profile-tournaments-table th,
.profile-tournaments-table td {
  text-align: left;
  padding: 10px;
  font-size: 1.2em;
}

.profile-tournaments-table th {
  color: #888;
}

.profile-tournaments-table td {
  color: white;
}

.profile-tournaments-table th,
.profile-tournaments-table td {
  border: none;
}

.profile-tournaments-table tbody tr:nth-child(odd) {
  background-color: #1C1C1C;
}

.profile-tournaments-table tbody tr:nth-child(odd) td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.profile-tournaments-table tbody tr:nth-child(odd) td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.connect-open-loot-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 15px;
  padding: 10px 20px;
  border-radius: 5px;
  border: 2px solid #4CAF50;
  cursor: pointer;
  transition: all 0.3s ease;
}

.connect-open-loot-button:hover {
  border-color: #45a049;
}

.profile-main-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  color: #f1f1f1;
  margin-top: 15px;
  padding: 12px 24px;
  border-radius: 8px;
  border: 2px solid #4CAF50;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  font-size: 16px;
}

.profile-main-button:hover {
  background-color: #444444;
  border-color: #45a049;
  color: #45a049;
}

.connect-open-loot-logo {
  // width: 25px;
  height: 30px;
  margin-right: 10px;
}

.connect-open-loot-text {
  color: #4CAF50;
  font-weight: bold;
  font-size: 1.1em;
}

@media (max-width: 1175px) {
  .profile-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }  
}

.whitelist-qorbi-button {
  margin: 0 auto;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right,
      #77530a,
      #ffd277,
      #77530a,
      #77530a,
      #ffd277,
      #77530a,
    );
  background-size: 250%;
  background-position: left;
  color: #ffd277;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
  transform: scale(1.25);
}

.whitelist-qorbi-button::before {
  position: absolute;
  content: "Add To Pool";
  color: #ffd277;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0.842);
  background-size: 200%;
}

.whitelist-qorbi-after::before {
  content: "In Pool";
  cursor: grab;
}

.whitelist-qorbi-button:hover {
  background-position: right;
  transition-duration: 1s;
}

.whitelist-qorbi-button:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.whitelist-qorbi-button:active {
  transform: scale(0.95);
}

// Beta Countdown
.beta-countdown-container {
  text-align: center;
  background: rgba(30, 30, 30, 0.8);
  padding: 60px;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  max-width: 800px;
  width: 80%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
}

.countdown-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: #33E37F;
}

.countdown-description {
  font-size: 1.4rem;
  margin-bottom: 2.5rem;
  color: #ddd;
  max-width: 600px;
  line-height: 1.6;
}

.countdown-timer {
  font-size: 3rem;
  background: #282828;
  padding: 30px;
  border-radius: 12px;
  display: inline-block;
  color: #0c0;
  min-width: 300px;
}

@media (max-width: 768px) {
  .beta-countdown-container {
    padding: 40px;
    width: 90%;
  }

  .countdown-title {
    font-size: 2.5rem;
  }

  .countdown-description {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .countdown-timer {
    font-size: 2.5rem;
    padding: 20px;
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .beta-countdown-container {
    padding: 30px;
  }

  .countdown-title {
    font-size: 2rem;
  }

  .countdown-description {
    font-size: 1rem;
  }

  .countdown-timer {
    font-size: 2rem;
    padding: 15px;
  }
}

/* Leaderboard Page */
.loading-message {
  font-size: 1.5rem;
  color: #38ef7d;
  text-align: center;
  margin-top: 50px;
  font-family: 'sf-black';
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.hover-floating-message {
  position: absolute;
  background-color: #444;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0.9;
  transform: translate(-50%, -100%);
  pointer-events: none;
}

.pagination-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.pagination-buttons button {
  padding: 10px 15px;
  background-color: #38ef7d;
  border: none;
  border-radius: 5px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pagination-buttons button:disabled {
  background-color: #a5a5a5;
  cursor: not-allowed;
}

.pagination-buttons button:hover:not(:disabled) {
  background-color: #2dbf62;
}

.pagination-buttons span {
  font-weight: bold;
  color: #333;
}

.leaderboard-page {
  margin-top: 100px;
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 40px;
}

.leaderboard-title {
  font-size: 64px;
  font-family: "sf-black";
  background: -webkit-linear-gradient($ligthGreen, $darkGreen);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Sidebar Navigation */
.leaderboard-sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  background-color: $black2;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  top: 100px;
  left: 0;
  height: fit-content;
}

.leaderboard-sidebar button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  white-space: nowrap;
}

.leaderboard-sidebar button:hover {
  transform: scale(1.05);
}

.leaderboard-sidebar button.active {
  border: 2px solid #ffffff;
  color: #000;
  transform: scale(1.1);
}

/* Sidebar Navigation Rank Buttons Styling */

.rank-button-global {
  background: linear-gradient(135deg, #78e2f0, #11998e);
  color: #000;
  border: 1px solid #11998e;
  box-shadow: 0 4px 10px rgba(17, 153, 142, 0.5);
}

.leaderboard-sidebar .rank-button-copper {
  background: linear-gradient(135deg, #b87333, #c08055);
  color: #fff;
  border: 1px solid #8a5a2c;
  box-shadow: 0 4px 8px rgba(139, 69, 19, 0.5);
}

.leaderboard-sidebar .rank-button-silver {
  background: linear-gradient(135deg, #c0c0c0, #d3d3d3);
  color: #000;
  border: 1px solid #a9a9a9;
  box-shadow: 0 4px 8px rgba(192, 192, 192, 0.5);
}

.leaderboard-sidebar .rank-button-gold {
  background: linear-gradient(135deg, #ffd700, #ffec8b);
  color: #000;
  border: 1px solid #b8860b;
  box-shadow: 0 4px 8px rgba(218, 165, 32, 0.5);
}

.leaderboard-sidebar .rank-button-platinum {
  background: linear-gradient(135deg, #e5e4e2, #dcdcdc);
  color: #000;
  border: 1px solid #b0b0b0;
  box-shadow: 0 4px 8px rgba(229, 228, 226, 0.5);
}

.leaderboard-sidebar .rank-button-diamond {
  background: linear-gradient(135deg, #b9f2ff, #87cefa);
  color: #000;
  border: 1px solid #5f9ea0;
  box-shadow: 0 4px 8px rgba(185, 242, 255, 0.5);
}

.leaderboard-sidebar .rank-button-ruby {
  background: linear-gradient(135deg, #e0115f, #ff0066);
  color: #fff;
  border: 1px solid #8b0000;
  box-shadow: 0 4px 8px rgba(224, 17, 95, 0.5);
}

.leaderboard-sidebar .rank-button-emerald {
  background: linear-gradient(135deg, #50c878, #3cb371);
  color: #fff;
  border: 1px solid #2e8b57;
  box-shadow: 0 4px 8px rgba(80, 200, 120, 0.5);
}

.leaderboard-sidebar .rank-button-sapphire {
  background: linear-gradient(135deg, #0f52ba, #4682b4);
  color: #fff;
  border: 1px solid #27408b;
  box-shadow: 0 4px 8px rgba(15, 82, 186, 0.5);
}

.leaderboard-content {
  flex: 1;
  margin-left: 80px;
  max-width: 70%;
}

/* Table Styles */
.leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #2b2b2b;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #444;
}

.leaderboard-table th {
  background-color: $green;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.leaderboard-table td {
  background-color: #333;
  color: #fff;
  font-size: 1.1rem;
}

.potential-earning {
  color: #38ef7d;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.potential-earning img {
  width: 30px;
}

.leaderboard-table tr:hover td {
  background-color: #444;
  color: #38ef7d;
  transition: background-color 0.3s, color 0.3s;
}

@media (max-width: 768px) {
  .rank-leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }

  .leaderboard-page {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    width: 100%;
    padding-left: 40px 0;
  }

  .leaderboard-content {
    margin-left: 0;
    width: 100%;
  }

  .leaderboard-sidebar {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }

  .leaderboard-sidebar button {
    margin-bottom: 10px;
    flex: 1;
    text-align: center;
  }

  .leaderboard-title {
    margin-top: 25px;
  }
}

/* Scrollbar Styling */
.leaderboard-sidebar::-webkit-scrollbar {
  height: 8px;
}

.leaderboard-sidebar::-webkit-scrollbar-thumb {
  background-color: $ligthGreen2;
  border-radius: 10px;
}

.leaderboard-sidebar::-webkit-scrollbar-track {
  background-color: $gray3;
  border-radius: 10px;
}

.leaderboard-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: $green;
}

/* OTP Component */
.otp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background-color: rgba(0, 128, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.otp-container {
  background-color: #e0f2e9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 128, 0, 0.3);
  max-width: 300px;
  width: 100%;
  text-align: center;
}

.otp-input {
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 5px;
  border: 2px solid #4caf50;
  border-radius: 4px;
  color: #2e7d32;
  background-color: #a5d6a7;
  transition: all 0.3s ease;
}

.otp-input:focus {
  outline: none;
  border-color: #66bb6a;
  box-shadow: 0 0 5px rgba(102, 187, 106, 0.5);
}

.otp-submit,
.otp-cancel {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.otp-submit:hover {
  background-color: #388e3c;
}

.otp-cancel {
  background-color: #b0bec5;
  color: #37474f;
}

.otp-cancel:hover {
  background-color: #78909c;
}

.switch-button-case {
  flex: 1;
  height: 100%;
  color: white;
  border: none;
  transition: background-color 0.8s ease, color 0.8s ease;
  text-transform: uppercase;
  letter-spacing: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.switch-button-case:hover {
  color: grey;
  cursor: pointer;
}

.switch-button-case:focus {
  outline: none;
}

.switch-button .active {
  position: absolute;
  top: 0;
  left: 0;
  width: 33.33%;
  height: 100%;
  z-index: -1;
  background-color: white;
  transition: 0.3s ease-out all;
}

.switch-button-case.active-case {
  color: #151515 !important;
  background-color: white;
}

.select-container,
.filter-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.select-container label,
.filter-container label {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.select-container select,
input#name-filter {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
}

input#name-filter {
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .controls {
    justify-content: space-between;
    gap: 1rem;
  }

  .select-container,
  .filter-container {
    flex: 1;
  }
}

@media (max-width: 767px) {
  .controls {
    flex-direction: column;
  }

  .select-container,
  .filter-container {
    width: 100%;
  }
}

.loader-layer-1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  text-align: center;
  color: #fff;
}

.loader-layer-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.snowflakes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  overflow: hidden;
}

.snowflake {
  position: absolute;
  top: -10px;
  font-size: 1.5rem;
  color: #fff;
  user-select: none;
  opacity: 0.8;
  animation: snowflake-fall var(--fall-duration) linear infinite, snowflake-twist 3s ease-in-out infinite;
  pointer-events: none;
}

@keyframes snowflake-fall {
  0% {
    top: -10px;
    opacity: 0.8;
  }

  100% {
    top: 100vh;
    opacity: 0.5;
  }
}

@keyframes snowflake-twist {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// .qorb-earning-container {
//   color: white;
//   font-weight: bold;
//   font-size: clamp(1rem, 2vw, 2.5rem);
//   margin-bottom: 20px;
//   display: flex;
//   flex-direction: row;
//   background-color: #1A1919;
//   border-radius: 30px;
//   justify-content: center;
//   align-items: center;
//   padding: 5px;
//   max-height: none;
//   gap: 10px;
// }

// .qorb-earning-container img {
//   width: auto;
//   height: 1.75em;
//   flex-shrink: 0;
// }

//*****************************************
.home-replica-title {
  font-size: 48px;
  font-family: "sf-black";
  background: -webkit-linear-gradient(#38ef7d, #11998e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.just-center {
  text-align: center;
}

.container-pool {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 75px;
}

.left-pool,
.right-pool {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.left-pool {
  padding-left: 40px;
}

.right-pool {
  align-items: center;
}

@media (max-width: 768px) {
  .container-pool {
    flex-direction: column;
  }

  .left-pool,
  .right-pool {
    margin-top: 15px;
    align-items: center;
  }

  .left-pool {
    padding: 0px 40px;
  }
}

.dropdown-container-pool {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  gap: 10px;
  margin: 15px auto 25px auto;
}

.dropdown-pool {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.carousel-pool {
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.carousel-image-pool {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.carousel-text-pool {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  text-align: center;
}

.carousel-title-pool {
  font-size: 1.5rem;
  margin: 0;
}

.carousel-description-pool {
  font-size: 1rem;
  margin: 5px 0 0 0;
}

.qorb-pool-text {
  color: white;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0 0 20px 0;
  text-align: justify;
  font-family: "Exo", sans-serif;
}

.qorb-pool-text p {
  color: #fff;
  font-size: 16px;
  margin-left: 25px;
}

.home-replica-subtitle {
  font-size: 32px;
  font-family: "sf-bold";
  color: #ffffff;
  text-align: center;
  margin-top: 10px;
  text-decoration: none;
}

.row-to-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.home-replica-subtitle:hover {
  color: green;
}

.how-it-works,
.why-join,
.faq {
  margin-top: 30px;
  border-radius: 10px;
}

.why-join {
  margin-bottom: 50px;
}

.section-title {
  font-size: 24px;
  font-family: "sf-bold";
  color: #38ef7d;
  margin-bottom: 15px;
}

.benefits-list {
  list-style: none;
  color: #ffffff;
  font-family: "exo", sans-serif;
}

.benefits-list li {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.benefits-list li::before {
  content: "✔";
  color: #38ef7d;
  font-weight: bold;
  margin-right: 10px;
}

.faq-item {
  margin-bottom: 15px;
}

.faq-item strong {
  color: #ffffff;
  font-family: "sf-bold";
}

.faq-item p {
  color: #cccccc;
  margin: 5px 0 0 0;
}

///////////////////////////////////////////////
.items-container-ember {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  width: 80%;
}

.item-card-ember {
  background: rgba(240, 240, 240, 0.95);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}

.item-card-ember:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.card-header-ember {
  background-color: rgba(240, 240, 240, 0.8);
  padding: 15px;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;
}

.item-image-ember {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 12px;
}

.card-body-ember {
  text-align: center;
  margin-bottom: 10px;
}

.h2-ember {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #444;
}

.price-ember {
  font-size: 1rem;
  color: #555;
  margin-bottom: 10px;
}

.card-footer-ember {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.button-ember {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s, transform 0.3s;
}

.button-ember.stripe {
  background-color: #5a6268;
}

.button-ember.coinbase {
  background-color: #6f42c1;
}

.button-ember:hover {
  background-color: #495057;
  transform: scale(1.05);
}

.button-ember.coinbase:hover {
  background-color: #563d7c;
}

////////////////// Fucking Hate Hackers
.maintenance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: #1b5e20;
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.maintenance-content {
  max-width: 600px;
  padding: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  background-color: #ffffff;
  text-align: center;
  border: 2px solid #a5d6a7;
}

.maintenance-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2e7d32;
  margin-bottom: 20px;
}

.maintenance-message {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #388e3c;
}

.maintenance-image {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.maintenance-support {
  font-size: 1rem;
  margin-top: 20px;
  color: #1b5e20;
}

.maintenance-link {
  color: #2e7d32;
  text-decoration: none;
  font-weight: bold;
}

.maintenance-link:hover {
  text-decoration: underline;
}

//Mystery
.results-mystery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  text-align: center;
}

.result-mystery-item {
  flex: 1 1 calc(33.333% - 40px);
  max-width: 300px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: #1e1e2f;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.result-mystery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.result-mystery-image {
  width: 100%;
  height: auto;
  border-bottom: 2px solid #ff0000;
  border: 3px solid #ff0000;
  border-radius: 10px;
  box-sizing: border-box;
}

.result-mystery-title {
  font-size: 20px;
  color: #ff0000;
  margin: 15px 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .row-to-column {
    flex-direction: column;
  }

  .result-mystery-item {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .result-mystery-item {
    flex: 1 1 100%;
  }
}

// Wallet Input Component

.wallet-input-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-width: 400px;
  width: 90%;
  padding: 20px;
  background-color: rgba(30, 30, 47, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #fff;
}

.wallet-input-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #8247e5;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.wallet-input-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.wallet-input-field {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #8247e5;
  border-radius: 8px;
  background-color: #2b2b3d;
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s;
}

.wallet-input-field:focus {
  border-color: #6f2cfc;
}

.wallet-submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6f2cfc;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.wallet-submit-button:hover {
  background-color: #8247e5;
}

.error-message {
  margin-top: 10px;
  font-size: 14px;
  color: #ff4444;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.confirmation-message {
  margin-top: 20px;
  font-size: 16px;
  color: #88ff88;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.loader-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  color: #fff;
  text-align: center;
}

//Ember Popup

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #333;
  color: #f1f1f1;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-content input {
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #4CAF50;
  background-color: #222;
  color: #f1f1f1;
}

.popup-content .error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.popup-actions {
  display: flex;
  justify-content: space-around;
}

.popup-actions button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.popup-actions button:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
}

.cta-button {
  margin-top: 25px;
  display: inline-block;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #28a745, #3bcf77);
  border: none;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 128, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 128, 0, 0.3);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 5px 10px rgba(0, 128, 0, 0.2);
}

.cta-button::before {
  content: "🌟 ";
  font-size: 20px;
  margin-right: 5px;
}

//Download
section.hero-download {
  margin-top: 25px;
  text-align: center;
  padding: 4rem 2rem;
  color: #FFFFFF;
}

.hero-download h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-download p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.hero-download {
  font-size: 1rem;
}

.hero-download-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
  margin-top: 20px;
}

section.features-download {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card-download {
  background: #000000;
  border: 2px solid #1eb588;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
}

.feature-card-download h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.feature-card-download p {
  font-size: 1rem;
  line-height: 1.5;
}

.buttons-container-download {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  font-size: 0.875rem;
}

.button-download {
  width: 180px;
  height: 60px;
  background: rgba(146, 0, 183, 0.5);
  color: #fff;
  transition: background 0.5s;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  overflow: hidden;
}

.button-download:hover {
  color: #fff;
  background: -webkit-linear-gradient(258deg, #9200b7, #d73d3b);
}

.button-download img {
  object-fit: contain;
}

.whitepaper-link {
  color: #4CAF50;
  text-decoration: none;
  display: inline-block;
}

.faq-item button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #4CAF50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 10px;
  transition: background-color 0.3s ease;
}